<template>
	<b-form-group class="payment-types mb-2">
		<b-card class="rounded-0 border-0 ">
			<div class="row payment-header border-bottom d-flex align-items-center mb-2" v-if="isCardPaymentAvailable()">
				Vyberte spôsob platby
			</div>
			<div class="row z-clickable py-2 payment-type" v-if="isCardPaymentAvailable()" :class="{ selected: cardPayment }" @click="cardPayment = true">
				<div class="col-9 d-flex align-items-center">
					<img src="@/plugins/app/order/components/img/credit_card.svg" class="mr-2" />
					<h6 class="font-weight-bold">
						Platba kartou
					</h6>
				</div>
				<div class="col-3 d-flex justify-content-end">
					<div class="radio"><div></div></div>
				</div>
			</div>
			<div class="row z-clickable py-2 payment-type" v-if="isCardPaymentAvailable()" :class="{ selected: !cardPayment }" @click="cardPayment = false">
				<div class="col-9 d-flex align-items-center">
					<img src="@/plugins/app/order/components/img/swap_horiz.svg" class="mr-2" />
					<h6 class="font-weight-bold">
						Platba prevodom
					</h6>
				</div>
				<div class="col-3 d-flex justify-content-end">
					<div class="radio"><div></div></div>
				</div>
			</div>
			<A-transfer-checkout />
		</b-card>
	</b-form-group>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { mapGetters, mapState } from 'vuex'

const api = {
	async stripePayment(successUrl, cancelUrl) {
		const orderId = localStorage.getItem('hemisfera_orderId')
		const payload = { success_url: successUrl, cancel_url: cancelUrl }
		const response = await wAxios.post_auth_data(`/v1/orders/${orderId}/checkout-session`, payload)
		return response.data.data.checkout_url
	},

	activateOrder() {
		const orderId = localStorage.getItem('hemisfera_orderId')
		return wAxios.put_auth(`/v1/orders/${orderId}/activate-order`).checkout_url
	}
}

export default {
	components: {
		ATransferCheckout: () => import('@/plugins/app/order/components/a-transfer-checkout.vue')
	},

	data() {
		return {
			cardPayment: true
		}
	},

	watch: {
		cardPayment(isCardPayment) {
			this.$emit('onPaymentTypeChanged', isCardPayment)
		}
	},

	emits: ['onPaymentTypeChanged'],

	computed: {
		...mapGetters('order', {
			orderProductType: 'orderProductType'
		}),
		...mapState('order', {
			orderPrice: 'orderPrice',
			orderIsPartialPayment: 'isPartialPayment'
		})
	},

	methods: {
		async payAndRedirect() {
			const successUrl = this.$router.resolve({ name: 'Acknowledgment', query: { type: this.orderProductType } }).href
			try {
				if (this.cardPayment) {
					if (!this.checkoutUrl()) {
						const checkoutResponse = await api.stripePayment(window.location.origin + successUrl, window.location.href)
						await localStorage.setItem('checkoutUrl', checkoutResponse)
					}
					window.location = this.checkoutUrl()
				} else if (localStorage.getItem('checkoutUrl')) {
					await api.activateOrder()
					await this.$router.push(successUrl)
				} else {
					await this.$store.dispatch('order/updateOrder', { consent: true })
					await this.$router.push(successUrl)
				}
			} catch (e) {
				console.log(e)
				this.$wToast.error({ error: e })
			}
		},

		isCardPaymentAvailable() {
			if (this.orderProductType == 'gamisfera-licencia') {
				this.cardPayment = false
				return false
			}
			const price = this.orderIsPartialPayment ? this.orderPrice.order_monthly_pay.price_monthly : this.orderPrice.order_once_pay.price
			if (price < 1) {
				this.cardPayment = false
				return false
			}

			return true
		},

		checkoutUrl() {
			return localStorage.getItem('checkoutUrl')
		}
	}
}
</script>
